<template>
  <div v-if="lottery" class="reward-manage">
    <PageTitle
      :title="`獎項管理 - ${lottery.name}`"
      icon="chevron_left"
      btn="基本設定"
      btn2="複製抽獎頁連結"
      :btn2Plain="true"
      @btnClick="gameEditDialog = true"
      @btn2Click="copyLink(lottery.liffLink)"
      @iconClick="router.push({ name: 'RewardContentSetting' })"
    />
    <section class="lottery-wrap">
      <BaseElForm :model="lottery" label-position="left" label-width="140px">
        <BaseElFormItem label="活動期間">
          {{ dateFormat(lottery.startAt) }} ~ {{ dateFormat(lottery.endAt) }}
        </BaseElFormItem>
        <BaseElFormItem label="活動中獎率">
          <div class="flex items-center gap-[4px]">
            <p class="text-primary-100"> {{ rounded(awardWinProbability * 100) }}%</p>
            <Tipinfo width="200">
              已扣除獎項名稱「再接再厲」的實際中獎率
            </Tipinfo>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="中獎率計算方式">
          <div class="flex items-center gap-[4px]">
            <p>{{ get(lottery, 'Lottery.enableCustomProbability') ? '手動編輯預期中獎率':'依獎項數量自動分配' }}</p>
            <Tipinfo width="300">
              <ul class="list-disc pl-[16px]">
                <li>各獎項的實際中獎率 = 獎項數量 / 獎項數量總和</li>
                <li>若獎項「已送完、失效、無庫存」，該獎項的中獎率按比例分配給其他獎項</li>
                <li>如欲編輯各獎項的中獎率，請點擊右側按鈕「編輯預期中獎率」</li>
              </ul>
            </Tipinfo>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </section>
    <section class="text-right">
      <BaseElButton
        class="btn"
        plain
        :disabled="!tableData.length"
        @click="modal.rate = true"
      >
        編輯預期中獎率
      </BaseElButton>
      <BaseElButton
        class="btn"
        type="primary"
        @click="openDialog('create')"
      >
        新增獎項
      </BaseElButton>
    </section>

    <!-- Table -->
    <RewardManageTable v-loading="loading.table" :tableData="tableDataCopy" @refresh="refresh" @delete="deleteReward" @edit="editReward" />
    <!-- Pagination -->
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh"
    />

    <!-- Dialog -->
    <el-dialog
      v-if="gameEditDialog"
      title="抽獎編輯"
      :visible.sync="gameEditDialog"
      width="620px"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <BaseElForm
        ref="lotteryForm"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem label="頁面圖片">
          <!-- <img :src="imgUrl(lottery.Image)" alt=""> -->
          <UploadButton
            :img="lottery.Image"
            :isAvatar="true"
            @change="imageChange"
          />
        </BaseElFormItem>
        <BaseElFormItem label="抽獎名稱">
          <span class="lottery-info">{{ lottery.name }}</span>
        </BaseElFormItem>
        <BaseElFormItem label="抽獎方式">
          <span class="lottery-info">{{ lottery.MemberGameMode ?lottery.MemberGameMode.name : '' }}</span>
        </BaseElFormItem>
        <BaseElFormItem label="抽獎條件設定">
          <p
            v-for="(req, index) in lotteryReqList()"
            :key="index"
            class="lottery-info"
            style="margin-bottom: 10px"
          >
            {{ req }}
          </p>
        </BaseElFormItem>
        <BaseElFormItem label="「再抽一次」按鈕延遲功能" prop="againDelay">
          <template slot="label">
            <FormItemTooltipLabel label="「再抽一次」按鈕延遲功能" :tooltipWidth="200">
              若開啟此設定，則會員完成抽獎當下，「再抽一次」按鈕將於 4 秒後才能點擊
            </FormItemTooltipLabel>
          </template>
          <BaseElSwitch
            v-model="formData.againDelay"
            inactive-text="關閉"
            active-text="開啟"
          />
        </BaseElFormItem>
        <BaseElFormItem label="抽獎活動期間" prop="lotteryDate" :error="dateError? '活動結束時間不可晚於使用券到期時間。': ''">
          <el-date-picker
            v-model="lotteryDate"
            editable
            format="yyyy-MM-dd HH:mm"
            type="datetimerange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            @change="(val) => (formData.lotteryDate = val)"
          />
        </BaseElFormItem>

        <BaseElFormItem label="活動說明">
          <quillEditor v-model="formData.description" :options="editorOption" />
        </BaseElFormItem>
        <BaseElFormItem label="注意事項">
          <quillEditor v-model="formData.notice" :options="editorOption" />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton
          plain
          @click=";(gameEditDialog = false), resetForm()"
        >
          返回
        </BaseElButton>
        <BaseElButton type="primary" @click="updateMemberGame">儲存</BaseElButton>
      </div>
    </el-dialog>

    <el-dialog
      v-if="showDialog"
      :title="dialogTitle"
      :visible.sync="showDialog"
      width="620px"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <BaseElForm
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem label="獎項類型" prop="type">
          <p v-if="dialogType === 'update'">
            {{ rewardTypesCreateConfig[formData.type].name }}
          </p>

          <BaseElSelect
            v-if="dialogType === 'create'"
            v-model="formData.type"
            placeholder="請選擇"
            @change="typeChange"
          >
            <BaseElSelectOption
              v-for="reward in rewardTypes"
              :key="reward.value"
              :label="reward.name"
              :value="reward.value"
            />
          </BaseElSelect>
          <p v-if="formData.type === 'miss'" class="gray">此獎項會降低活動中獎率</p>
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.type === 'point' || formData.type === 'cashback'"
          :label="formatAmountText(formData.type)"
          prop="amount"
        >
          <p v-if="dialogType === 'update'">{{ formData.amount }}</p>
          <BaseElInput
            v-if="dialogType === 'create'"
            v-model="formData.amount"
            placeholder="請輸入數字"
            type="number"
            :min="0"
            :max="defaultRangeMax"
          />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.type === 'coupon'"
          label="票券綁定"
          prop="couponId"
        >
          <BaseElSelect
            v-model="formData.couponId"
            popper-class="select-popper"
            placeholder="選擇票券"
            @visible-change="showCouponSelectModal = true"
          >
            <BaseElSelectOption
              :label="findCouponName(formData.couponId)"
              :value="formData.couponId"
            />
          </BaseElSelect>
          <p v-if="formData.status ==='notExist'" class="danger">找不到先前綁定的票券，請重新綁定票券</p>
          <div v-if="showCouponExchange">
            <div v-if="formData.status === 'noStock'">
              <p class="danger">庫存數量：0</p>
              <p class="gray">此票券為「兌換券」，請留意兌換碼剩餘數量。相關設定請至「票券模組 > 兌換券設定 > 兌換碼管理」查看</p>
            </div>
            <div v-if="formData.status === 'notEnoughStock'">
              <p class="primary">庫存數量：{{ couponStock(formData.awardCouponId) }}</p>
              <p class="gray">此票券為「兌換券」，請留意兌換碼剩餘數量。相關設定請至「票券模組 > 兌換券設定 > 兌換碼管理」查看</p>
            </div>
          </div>
        </BaseElFormItem>
        <!-- <BaseElFormItem label="機率" prop="probability">
          <BaseElInput
            v-model="formData.probability"
            type="number"
            min="0"
            max="100"
            placeholder="獎項機率 0~100%"
          >
            <template slot="suffix">
              %
            </template>
          </BaseElInput>
        </BaseElFormItem> -->
        <BaseElFormItem label="獎項數量" prop="availableStock">
          <BaseElInput
            v-model="formData.availableStock"
            type="number"
            :min="0"
            :max="defaultRangeMax"
            :placeholder="`請輸入數字0~${defaultRangeMax}`"
          />
          <p v-if="showCouponExchange && (formData.status === 'noStock' || formData.status === 'notEnoughStock') && couponStock(formData.awardCouponId) < formData.lastStock" class="warning">庫存數量不足，建議補足庫存 {{ ((formData.lastStock) - couponStock(formData.awardCouponId)) || '-' }} 件 (還需送出 {{ formData.lastStock }} 份獎項)</p>
          <BaseElCheckbox v-if="formData.type !== 'miss'" v-model="formData.showAvailableStock" data-cy="hide-service-price">前台顯示數量</BaseElCheckbox>
        </BaseElFormItem>
        <!-- <BaseElFormItem prop="showAvailableStock">
          <BaseElSwitch
            v-model="formData.showAvailableStock"
            active-text="前台顯示數量"
            inactive-text="前台不顯示數量"
          />
        </BaseElFormItem> -->
        <BaseElFormItem v-if="formData.type !== 'miss'" label="獎項名稱" prop="name">
          <p v-if="dialogType === 'update'">{{ formData.name }}</p>
          <BaseElInput
            v-if="dialogType === 'create'"
            v-model="formData.name"
            placeholder="請輸入名稱"
          />
        </BaseElFormItem>
        <BaseElFormItem v-if="formData.type !== 'miss'" label="圖片" prop="img">
          <UploadButton
            :img="formData.img"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton
          plain
          @click=";(showDialog = false), resetForm()"
        >
          返回
        </BaseElButton>
        <BaseElButton type="primary" @click="dialogConfirm">
          {{
            dialogType === 'create' ? '新增' : '儲存'
          }}
        </BaseElButton>
      </div>
    </el-dialog>

    <ImageCropper
      v-if="uploadDialog"
      :image="uploadImg"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
    <ImageCropper
      v-if="uploadLotteryDialog"
      :image="image"
      @close="uploadLotteryDialog = false"
      @uploaded="getLotteryImage"
    />
    <CouponAdvanceSelect
      v-if="showCouponSelectModal"
      :data="couponList"
      :typeOptions="couponTypeConfig"
      disabledExp
      @confirm="onAddCoupon"
      @close="showCouponSelectModal = false"
    />
    <GameRateDialog
      v-if="modal.rate"
      :data="tableData"
      :enableCustomProbability="get(lottery, 'Lottery.enableCustomProbability')"
      @close="modal.rate = false"
      @refresh="refresh"
      @deleteMiss="deleteMiss"
      @rateTypeChange="previewAwardProbability"
    />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed, toRef, set, onBeforeMount } from 'vue'
import { lotteryReqConfig, rewardTypesCreateConfig } from '@/config/lottery'
import { GetMemberLevelConfig } from '@/api/memberLevel'
import { rangeRules, noEmptyRules, defaultRangeMax } from '@/validation/index'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import RewardManageTable from './components/RewardManageTable.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
// Mixin
import imageMixin from '@/mixin/image'
// APIs
import { FindMemberGame, UpdateMemberGame } from '@/api/lottery/memberGame'
import {} from '@/api/memberTags'
import {
  GetRewardCount,
  CreateReward,
  UpdateReward,
  DeleteReward,
  GetAwardProbability,
  PreviewAwardProbability,
} from '@/api/lottery/reward'
import { FindCoupon, GetCoupon, GetCouponCount } from '@/api/lottery/coupon'
import formUtils from '@/utils/form'
import copy from 'clipboard-copy'
import { imgSrc, getAllDataFromApi } from '@/utils/helper'
import { forEach, map, find, omit, get, filter, cloneDeep } from 'lodash'
import dayjs from '@/lib/dayjs'

// Editor
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import CouponAdvanceSelect from '@/components/Select/CouponAdvanceSelect.vue'
import { couponTypeConfig } from '@/config/couponExchange'
import { usePermissions } from '@/use/permissions'
import Tipinfo from '@/components/TipInfo.vue'
import GameRateDialog from './components/GameRateDialog.vue'
import { useRouter, useRoute } from 'vue-router/composables'
import { useTable } from '@/use/table'
import { toFixed } from '@/utils/number'
import { useLottery } from '@/use/useLottery'
import { useFetch } from '@/use/fetch'

export default defineComponent({
  name: 'RewardManage',
  components: {
    quillEditor,
    UploadButton,
    ImageCropper,
    CouponAdvanceSelect,
    Tipinfo,
    RewardManageTable,
    GameRateDialog,
    FormItemTooltipLabel,
  },
  mixins: [imageMixin],
  setup () {
    const { checkAction } = usePermissions()
    const { simpleFetch } = useFetch()
    const useCoupon = computed(() => checkAction('admin.coupon.page'))
    const useExchangeCoupon = computed(() => checkAction('admin.couponExchange.page'))

    const useRebate = computed(() => checkAction('adminView.ecommerceProduct.useCashbackRate'))
    const useShopPoint = computed(() => checkAction('admin.shopPoint.page'))

    const rewardTypes = computed(() => {
      const omitList = []
      if (!useCoupon.value && !useExchangeCoupon.value) omitList.push('coupon')
      if (!useRebate.value) omitList.push('cashback')
      if (!useShopPoint.value) omitList.push('point')
      if (get(lottery.value, 'Lottery.enableCustomProbability') || hasMiss.value) omitList.push('miss')
      return omit(rewardTypesCreateConfig, omitList)
    })
    const modal = reactive({
      rate: false,
    })
    const dialogTitle = computed(() => dialogType.value === 'create' ? '新增獎項' : '編輯獎項')
    const router = useRouter()
    const route = useRoute()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchDataCount,
      dateFormat,
    } = useTable()
    const memberLevelConfig = ref([])
    const tableDataCopy = ref([])
    const lottery = ref(null)
    const lotteryDate = ref([])
    const formData = reactive({
      lotteryDate: '',
      img: null,
      name: '',
      type: '',
      availableStock: '',
      newProbability: '',
      couponId: '',
      amount: '',
      coupon: '',
      showAvailableStock: false,
      description: '',
      notice: '',
      status: '',
      againDelay: false,
    })
    const formRules = reactive({
      lotteryDate: [
        {
          required: true,
          message: '請輸入資料',
          trigger: 'blur',
        },
      ],
      // img: [noEmptyRules()],
      name: [noEmptyRules()],
      newProbability: [noEmptyRules(), rangeRules(0, 100)],
      availableStock: [noEmptyRules(), rangeRules(-1)],
      type: [noEmptyRules()],
      amount: [noEmptyRules(), rangeRules()],
      couponId: [noEmptyRules()],
    })
    const editorOption = {
      modules: {
        toolbar: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    }
    const couponList = ref([])
    const showDialog = ref(false)
    const dialogType = ref('create')
    const image = ref(null)
    const uploadLotteryDialog = ref(false)
    const showCouponSelectModal = ref(false)
    const lotteryReq = reactive(lotteryReqConfig)
    const couponTypeConfig = computed(() => {
      const omitList = []
      if (!this.useCoupon) omitList.push('coupon')
      if (!this.useExchangeCoupon) omitList.push('exchange')
      return omit(couponTypeConfig, omitList)
    })

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        lotteryId: route.params.lotteryId,
      }
      await Promise.allSettled([
        // fetchData(GetReward, payload),
        getAwardProbability(),
        fetchDataCount(GetRewardCount, payload),
      ])
    }
    const findMemberGame = async () => {
      const [res, err] = await FindMemberGame({
        shopId: shopId.value,
        id: route.query.id,
      })
      if (err) return window.$message.error(err)
      console.log(res)
      lottery.value = res
      lotteryDate.value = [res.startAt, res.endAt]
      formData.lotteryDate = lotteryDate.value
      formData.notice = lottery.value.notice
      formData.description = lottery.value.description
      formData.againDelay = lottery.value.againDelay
    }
    const refresh = async () => {
      loading.table = true
      await getTableData()
      await findMemberGame()
      loading.table = false
    }
    const awardWinProbability = ref(0)
    const getAwardProbability = async () => {
      const [res, err] = await GetAwardProbability({
        shopId: shopId.value,
        lotteryId: route.params.lotteryId,
      })
      if (err) window.$message.error(err)
      tableData.value = res.lotteryProbabilityList
      tableDataCopy.value = cloneDeep(tableData.value)
      awardWinProbability.value = res.awardWinProbability
    }

    const previewAwardProbability = async () => {
      const submitData = map(tableData.value, (item) => {
        return {
          LotteryAwardId: get(item, 'LotteryAwardId'),
          newProbability: 0,
          availableStock: get(item.LotteryAward, 'availableStock'),
        }
      })
      const [res, err] = await PreviewAwardProbability({
        shopId: shopId.value,
        lotteryId: route.params.lotteryId,
        data: {
          overwriteEnableCustomProbability: false,
          overwriteProbabilityList: submitData,
        },
      })
      if (err) window.$message.error(err)
      tableData.value = res.lotteryProbabilityList
    }

    const getAllCoupon = async () => {
      let max
      try {
        max = await getCouponCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
        type: 'all',
      }
      if (!useCoupon.value && useExchangeCoupon.value) config.type = 'exchange'
      else if (useCoupon.value && !useExchangeCoupon.value) config.type = 'coupon'
      const [res, err] = await getAllDataFromApi(
        max,
        GetCoupon,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      couponList.value = res
    }
    const getCouponCount = async () => {
      const [res, err] = await GetCouponCount({ shopId: shopId.value, type: 'all' })
      if (err) throw err
      return res.count
    }
    const deleteReward = async (row) => {
      const [, err] = await DeleteReward({
        shopId: shopId.value,
        lotteryId: route.params.lotteryId,
        id: row.id,
      })
      if (err) return window.$message.error(err)
      await refresh()
    }
    const hasMiss = computed(() => {
      return tableData.value.findIndex(item => item.type === 'miss') > -1
    })
    const deleteMiss = () => {
      if (hasMiss.value) {
        const miss = find(tableData.value, item => item.type === 'miss')
        deleteReward({ id: miss.LotteryAwardId })
      }
    }
    const syncFormData = async (res) => {
      formData.img = res.Image
      formData.name = res.name
      formData.type = res.award?.type
      formData.amount = res.award?.amount
      formData.showAvailableStock = res.showAvailableStock
      formData.unlimitedStock = res.unlimitedStock
      formData.newProbability = res.newProbability * 100
      formData.availableStock = res.availableStock
      formData.couponId = res.award?.couponId
      formData.description = res.description
      formData.notice = res.notice
      formData.status = res.type
      formData.lastStock = res.lastStock
      formData.awardCouponId = res.awardCouponId
      if (res.award?.type === 'coupon') await findCoupon(res.award?.couponId)
    }
    const findCoupon = async (couponId) => {
      const [res, err] = await FindCoupon({
        shopId: shopId.value,
        id: couponId,
      })

      if (err) {
        if (err.errorCode === 'FINDONE_NOT_FOUND') {
          formData.status = 'notExist'
          return window.$message.error(err.msg)
        }
      }
      formData.coupon = res
    }
    const editReward = async (row) => {
      openDialog('update')
      selectRow.value = row
      await syncFormData(row)
    }
    const openDialog = (type) => {
      showDialog.value = true
      dialogType.value = type
    }
    const dialogConfirm = async () => {
      const handlerDict = {
        create: createReward,
        update: updateReward,
      }
      await handlerDict[dialogType.value]()
    }
    const form = ref(null)
    const dateError = ref(false)
    const createReward = async () => {
      if (formData.type === 'coupon') {
        await findCoupon(formData.couponId)
        if (formData.coupon.expAt && dayjs(formData.coupon.expAt).isBefore(dayjs(lottery.value.endAt))) {
          dateError.value = true
          window.$message.warning('使用券截止日期不可早於活動結束日')
          return
        }
      }

      if (!(await formUtils.checkForm(form.value))) return
      if (formData.type === 'miss' && hasMiss.value) {
        window.$message.error('已有再接再厲，請勿重複新增')
        return
      }
      if (formData.type === 'miss') formData.name = '再接再厲'
      const [, err] = await CreateReward({
        shopId: shopId.value,
        lotteryId: route.params.lotteryId,
        ImageId: formData.img ? formData.img.id : undefined,
        name: formData.name,
        availableStock: formData.availableStock,
        showAvailableStock: formData.showAvailableStock,
        unlimitedStock: formData.availableStock === '-1',
        // newProbability: formData.newProbability / 100,
        award: {
          type: formData.type,
          amount:
            formData.type === 'point' || formData.type === 'cashback' ? formData.amount : undefined,
          couponId:
            formData.type === 'coupon' ? formData.couponId : undefined,
        },
      })
      if (err) return window.$message.error(err)
      resetForm()
      showDialog.value = false
      await refresh()
    }
    const selectRow = ref(null)
    const updateReward = async () => {
      if (!(await formUtils.checkForm(form.value))) return
      const [res, err] = await UpdateReward({
        shopId: shopId.value,
        lotteryId: route.params.lotteryId,
        id: selectRow.value.id,
        ImageId: formData.img ? formData.img.id : undefined,
        name: formData.name,
        availableStock: formData.availableStock,
        showAvailableStock: formData.showAvailableStock,
        unlimitedStock: formData.availableStock === -1,
        // newProbability: formData.newProbability / 100,
      })
      if (err) return window.$message.error(err)

      showDialog.value = false
      await refresh()
      await syncFormData(res)
    }
    const lotteryForm = ref(null)
    const resetForm = () => {
      dateError.value = false
      if (lotteryForm.value) formUtils.resetForm(lotteryForm.value)
      if (form.value) formUtils.resetForm(form.value)
      resetFormData()
      const dateData = JSON.parse(JSON.stringify(lottery.value))
      const lotteryData = JSON.parse(JSON.stringify(lottery.value))
      lotteryDate.value = [dateData.startAt, dateData.endAt]
      formData.lotteryDate = lotteryDate.value
      formData.notice = lotteryData.notice
      formData.description = lotteryData.description
    }
    const resetFormData = () => {
      formData.lotteryDate = ''
      formData.img = null
      formData.name = ''
      formData.type = ''
      formData.availableStock = ''
      formData.newProbability = ''
      formData.couponId = ''
      formData.amount = ''
      formData.coupon = ''
      formData.showAvailableStock = false
      formData.description = ''
      formData.notice = ''
    }
    const typeChange = async (type) => {
      formData.availableStock = ''
    }
    const imgUrl = (src) => {
      return imgSrc(150, src)
    }
    const onAddCoupon = (coupon) => {
      if (coupon.length > 0) {
        formData.couponId = coupon[0]
        formData.status = 'notEnoughStock'
        formData.awardCouponId = formData.couponId
      }
    }
    const showCouponExchange = computed(() => {
      const item = find(couponList.value, { id: formData.couponId })
      return get(item, 'type') === 'exchange'
    })
    const couponStock = (couponId) => {
      const item = find(couponList.value, { id: couponId })
      return get(item, 'sentLimit') - get(item, 'sentCount')
    }
    const findCouponName = (id) => {
      const coupon = find(couponList.value, { id })
      // console.log(coupon)
      if (coupon) return coupon.name
      return ''
    }
    const formatAmountText = (type) => {
      const config = {
        point: '點數',
        cashback: '回饋金',
      }
      return config[type]
    }
    const gameEditDialog = ref(false)
    const updateMemberGame = async () => {
      if (!(await formUtils.checkForm(lotteryForm.value))) return
      const [, err] = await UpdateMemberGame({
        name: get(lottery, 'value.name'),
        shopId: shopId.value,
        id: get(lottery, 'value.id'),
        startAt: get(lotteryDate, 'value[0]'),
        endAt: get(lotteryDate, 'value[1]'),
        notice: formData.notice,
        description: formData.description,
        ImageId: get(lottery, 'value.Image') ? get(lottery, 'value.Image.id') : undefined,
        requirements: map(get(lottery, 'value.requirements'), item => {
          return {
            tags: item.tags,
            times: item.times,
            levels: item.levels,
            type: item.type,
            costAmount: item.costAmount,
          }
        }),
        memberGameModeId: get(lottery, 'value.MemberGameMode.id'),
        againDelay: formData.againDelay,
      })
      if (err) {
        if (err.code === 'COUPON_EXPAT_MUST_LESS_THAN_ENDAT') {
          dateError.value = true
        }
        window.$message.error(err.msg || err)
        return
      }
      gameEditDialog.value = false
      await refresh()
    }
    const copyLink = (url) => {
      copy(url)
      window.$message.success('已成功複製連結！')
    }
    const rounded = (value) => {
      return toFixed(value, 2)
    }
    const imageChange = (img) => {
      image.value = img
      uploadLotteryDialog.value = true
    }
    const getLotteryImage = (data) => {
      set(lottery.value, 'Image', data)
      uploadLotteryDialog.value = false
    }
    const lotteryReqList = () => {
      const res = []
      forEach(get(lottery, 'value.requirements'), (req) => {
        res.push(lotteryReq[req.type].name)

        if (req.type === 'memberCard') {
          const levelList = map(
            req.levels,
            (level) => get(find(memberLevelConfig.value, { level }), 'name'),
          )
          res.push(levelList.join('、'))
        }
        if (req.type === 'totalUsed' || req.type === 'dayUsed') {
          res.push(req.times)
        }
        if (req.type === 'costPoint') {
          res.push(`每次扣除 ${req.costAmount} 點`)
        }

        // TODO 會員標籤關聯? 難道要打API拿所有標籤比對?
        if (req.type === 'memberTag') {
          const tags = map(req.mTags, 'name')
          res.push(tags.join('、'))
        }
      })
      return res
    }

    onBeforeMount(async () => {
      simpleFetch(GetMemberLevelConfig, {
        shopId: shopId.value,
      }, (res) => {
        memberLevelConfig.value = res
      })
    })
    onMounted(async () => {
      await refresh()
      if (useCoupon.value || useExchangeCoupon.value) await getAllCoupon()
    })
    return {
      useCoupon,
      useExchangeCoupon,
      rewardTypes,
      useRebate,
      useShopPoint,
      router,
      lottery,
      lotteryDate,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      formData,
      formRules,
      editorOption,
      refresh,
      deleteReward,
      editReward,
      showDialog,
      openDialog,
      dialogTitle,
      dialogType,
      dateFormat,
      dialogConfirm,
      dateError,
      form,
      imgUrl,
      onAddCoupon,
      findCouponName,
      couponList,
      formatAmountText,
      updateMemberGame,
      gameEditDialog,
      lotteryForm,
      resetForm,
      modal,
      copyLink,
      rounded,
      awardWinProbability,
      get,
      hasMiss,
      deleteMiss,
      couponStock,
      showCouponExchange,
      typeChange,
      previewAwardProbability,
      tableDataCopy,
      rewardTypesCreateConfig,
      imageChange,
      image,
      uploadLotteryDialog,
      getLotteryImage,
      showCouponSelectModal,
      lotteryReqList,
      defaultRangeMax,
      lotteryReq,
    }
  },
})
</script>

<style lang="scss" scoped>
.lottery-wrap {
  @apply ml-[32px];
}
::v-deep .lottery-wrap .el-form-item {
  @apply mb-[12px];
}
::v-deep .lottery-wrap .el-form-item__content {
  @apply leading-normal text-gray-60 text-normal;
}
::v-deep .el-form-item__label {
  line-height: unset;
  @apply text-gray-100 text-normal;
}

.lottery-link {
  @apply flex items-center justify-between bg-white rounded-full py-[8px] px-[20px];
  @apply w-[472px];

  .copy-btn {
    @apply bg-secondary-100 text-white rounded-full cursor-pointer;
    @apply px-[30px] py-[1px] ml-[12px] w-[96px] whitespace-nowrap;
  }
}

.lottery-info {
  @apply text-normal font-normal leading-[24px] tracking-[1px];
}

.url-text {
  @apply text-gray-80 mr-[30px];
  @apply w-[250px] whitespace-nowrap overflow-hidden overflow-ellipsis;
}
.btn {
  @apply min-w-[100px] text-normal;
}
.warning {
  @apply text-warning text-sm leading-normal;
}
.danger {
  @apply text-danger text-sm leading-normal;
}
.gray {
  @apply text-gray-80 text-sm leading-normal;
}
.primary {
  @apply text-primary-100 text-sm leading-normal;
}
</style>
